// context/ThemeContext.js
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  SetStateAction,
} from "react";

import zhCN from "antd/locale/zh_CN";
import { ConfigProvider, theme as antdTheme } from "antd";

// 定义 ThemeContext 和 UserContext 的类型
interface ThemeContextType {
  theme: string;
  toggleTheme: (theme: string) => void;
}
interface userInfoType {
  phone_number: string;
  email: string;
  username: string;
  avatar: string;
  last_login_time: string;
  uuid: string;
  balance: number;
  account_type: string;
  accaccount_type_name: string;
  is_bind_wechat: boolean;
  is_bind_github: boolean;
  subscription_expired: string;
  is_finished: boolean;
}
interface UserContextType {
  userInfo: userInfoType | any;
  toggleUserInfo: (userInfo: Record<string, any>) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);
const UserContext = createContext<UserContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = (props: { children: any }) => {
  const [theme, setTheme] = useState("light"); // 默认主题为 light
  const [userInfo, setUserInfo] = useState<any>({});

  useEffect(() => {
    // 检查本地存储中的主题并应用
    const savedTheme = localStorage.getItem("theme");
    if (
      !!userInfo?.theme_color &&
      (userInfo?.theme_color === 2 ? "light" : "dark") !== savedTheme
    ) {
      setTheme(userInfo?.theme_color === 2 ? "light" : "dark");
    } else if (savedTheme) {
      setTheme(savedTheme);
    }
  }, [userInfo]);
  useEffect(() => {
    // debugger
    const root = document.documentElement;
    if (theme === "light") {
      root.style.setProperty("--primary-color", "#000000");
      root.style.setProperty("--background-color", "rgba(249, 249, 249, 1)");
      root.style.setProperty(
        "--modal-background-color",
        "rgba(249, 249, 249, 1)"
      );
      root.style.setProperty("--login-logo-color", "#005BD8");
      root.style.setProperty("--login-right-bg", "#ffffff");
      root.style.setProperty("--login-right-title", "#222222");
      root.style.setProperty("--login-form-bg", "#F6F7FB");
      root.style.setProperty("--login-box-bg", "rgba(255, 255, 255, 1)");
      root.style.setProperty("--login-remember-text", "#222222");
      root.style.setProperty("--login-form-placeholder", "#8E8E8E");
      root.style.setProperty("--slider-logo", "#005BD8");
      root.style.setProperty("--slider-text", "#454545");
      root.style.setProperty("--slider-bg", "#F2F2F2");
      root.style.setProperty("--slider-line", "#E7E7E7");
      root.style.setProperty("--chat-bg", "#F9F9F9 ");
      root.style.setProperty("--chat-ask-bg", "#EEEEEE");
      root.style.setProperty("--chat-left-bg", "#F6F7FB");
      root.style.setProperty("--chat-left-text", "#222222");
      root.style.setProperty("--chat-tips-text", "#222222");
      root.style.setProperty("--chat-tips-bg", "#9DC6FF");
      root.style.setProperty("--chat-send-bg", "rgba(239, 239, 239, 1)");
      root.style.setProperty("--chat-list-active-bg", "#007AFF");
      root.style.setProperty("--setting-disabled-bg", "#F6F6F6");
      root.style.setProperty("--setting-input-bg", "rgba(239, 239, 239, 1)");
      root.style.setProperty("--setting-table-text", "#222222");
      root.style.setProperty("--setting-table-border", "#E7E7E7");
      root.style.setProperty(
        "--eagle-login-background",
        "url('/login/eagle_background_light.png')"
      );
      root.style.setProperty("--copy-icon", "url('/index/copy_light.png')");
      root.style.setProperty("--copy-icon-hover_bg", "#f9f9f9");
      root.style.setProperty("--user-icon-light", "url('/user_light.png')");
      root.style.setProperty("--scrollbar-color", "#8A8A8A");
      root.style.setProperty("--tree-select-color", "rgb(210, 210, 210)");
      root.style.setProperty("--chat-tips-bg", "#ffffff");
      root.style.setProperty("--chat-tips-color", "#8E8E8E");
      root.style.setProperty("--chat-tips-icon", "url('/chat/mind_light.png')");
      root.style.setProperty("--dragover-bg", "rgba(255, 255, 255, 0.9)");
    } else {
      root.style.setProperty("--primary-color", "#ffffff");
      root.style.setProperty("--background-color", "#0F040A");
      root.style.setProperty("--modal-background-color", "#363636");
      root.style.setProperty("--login-logo-color", "#86ADE8");
      root.style.setProperty("--login-right-bg", "#000000");
      root.style.setProperty("--login-right-title", "#ffffff");
      root.style.setProperty("--login-remember-text", "#F2F4F8");
      root.style.setProperty("--login-form-bg", "#3f3f3f");
      root.style.setProperty("--login-box-bg", "#1d222f");
      root.style.setProperty("--login-form-placeholder", "#E0E0E0");
      root.style.setProperty("--slider-logo", "#ffffff");
      root.style.setProperty("--slider-text", "#ffffff");
      root.style.setProperty("--slider-bg", "#1D1D1C ");
      root.style.setProperty("--slider-line", "#CDCDCD");
      root.style.setProperty("--chat-bg", "#282828");
      root.style.setProperty("--chat-ask-bg", "#424242");
      root.style.setProperty("--chat-left-bg", "#282828");
      root.style.setProperty("--chat-left-text", "#ffffff");
      root.style.setProperty("--chat-tips-text", "#D9D9D9");
      root.style.setProperty("--chat-tips-bg", "#9DC6FF");
      root.style.setProperty("--chat-send-bg", "#3f3f3f");
      root.style.setProperty("--chat-list-active-bg", "#3f3f3f");
      root.style.setProperty("--setting-disabled-bg", "#7D7D7D");
      root.style.setProperty("--setting-input-bg", "#222222");
      root.style.setProperty("--setting-table-text", "#D6D6D7");
      root.style.setProperty("--setting-table-border", "#535353");
      root.style.setProperty(
        "--eagle-login-background",
        "url('/login/eagle_background.png')"
      );
      root.style.setProperty("--copy-icon", "url('/index/copy.png')");
      root.style.setProperty("--copy-icon-hover_bg", "#424242");
      root.style.setProperty("--user-icon-light", "url('/user.jpg')");
      root.style.setProperty("--scrollbar-color", "#3f3f3f");
      root.style.setProperty("--tree-select-color", "#3f3f3f");
      root.style.setProperty("--chat-tips-bg", "#3f3f3f");
      root.style.setProperty("--chat-tips-color", "#D9D9D9");
      root.style.setProperty("--chat-tips-icon", "url('/chat/mind.png')");
      root.style.setProperty("--dragover-bg", "rgba(0, 0, 0, 0.8)");
    }

    // 保存到本地存储
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = (theme: string) => {
    // debugger
    setTheme(theme);
  };
  const toggleUserInfo = (userInfo: Record<string, any>) => {
    setUserInfo(userInfo);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <UserContext.Provider value={{ userInfo, toggleUserInfo }}>
        <ConfigProvider
          locale={zhCN}
          theme={{
            algorithm:
              theme === "dark"
                ? antdTheme.darkAlgorithm
                : antdTheme.defaultAlgorithm,
          }}
        >
          {props.children}
        </ConfigProvider>
      </UserContext.Provider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const useUserInfo = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserInfo must be used within a UserProvider");
  }
  return context;
};
